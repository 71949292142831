#mreg-follow-up {

	#mainContent {
		h3 {
			background: @color-background;
			margin-top: 20px;
			padding: 0 20px;

			color: @color-intermediate;
			font-size: 16px;
			line-height: 40px;

			.font-bold;
		}

		.bloc_content {
			border: 1px solid @color-background;
			margin-bottom: 20px;
			padding: 15px;

			div {
				line-height: 26px;
			}

			label {
				display: inline-block;
				min-width: 280px;
				padding-right: 20px;

				text-align: right;

				.font-bold;
			}
		}

		.button.back {
			float: right;
		}

		.footer {
			padding: 20px 0;
		}
	}

}
#mreg-follow-up #mainContent .bloc_content span {
    display: inline-block;
}
