#regulated-mandat-page {

	.bloc-content {
		& > div {
			border: 1px solid @color-background;
			border-bottom: 0;
			padding: 15px;

			& > div {
				padding: 25px 0 0 15px;

				&:first-child {
					padding-top: 0;
				}

				p {
					padding: 0 0 10px;

				}

				& > div {
					padding: 5px 0 10px 15px;
				}
			}
		}
	}

	.buttons {
		border: 1px solid @color-background;
		border-top: 0;
		padding: 15px;
		margin: 0px;
	}

	.positive_transfer{

		p{
			padding: 10px;
		}
	}
	.permanent_transfer{

		p{
			padding: 10px;
		}
	}

	label.required:after{
		content:"*";
		color:#dc1220;
	}

}
