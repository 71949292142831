// TABLE - CLASSIC STYLE

table {
	table-layout: fixed;
	width: 100%;

	thead {
		th {
			font-size: 12px;
		}
		th:nth-child(1) { width: 24%; }
		th:nth-child(2) { width: 23%; }
		th:nth-child(3) { width: 23%; }
		th:nth-child(4) { width: 15%; }
		th:nth-child(5) { width: 15%; }
	}

	tbody {
		tr {
			border-top: 2px solid @color-background;

			&:first-child {
				border-top: 0;
			}
		}
	}

	tr {
		&:hover {

			background-color: fade(@color-background, 60);

		}
	}

	th {
		background-color: @color-background;
		padding: 0 5px;

		color: @color-intermediate;
		line-height: 32px;
		text-align: left;

		.font-bold;
	}

	td {
		border-top: 1px solid @color-background;
		padding: 8px 5px;

		font-size: 12px;
		line-height: 16px;
		text-align: left;
		> span:not(.panel-link-col) {
			word-wrap: break-word;
		}
		/*text-overflow: ellipsis;
		white-space: nowrap;*/

		a{
			color:@color-primary;
		}

		.with-tooltip{
			display: inline;
		}

	}

	// EXPAND ARROWS
	a.expand {
		display: block;
		position: relative;
		text-indent: -99999px;

		&:before {
			content: "";
			background-image: url(img/sprite-arrows.png);
   			background-repeat: no-repeat;
    		display: block;
    		height: 15px;
    		right: 10px;
    		position: absolute;
    		top: 0;
    		width: 15px;

    		// GRAY ARROW
    		background-position: 0 0;
		}

		&:hover:before {
			background-position: 0 -30px;
		}

		&:focus:before {
			background-position: 0 0;
		}
	}

	.row-expanded {
		background-color: fade(@color-background, 70);

		a.expand:before {
			background-position: 0 -15px;
		}
	}

	#rowDetails {
		td {
			padding: 0;
		}

		.panel-inner .bloc {
			background-color: fade(@color-background, 30);
			padding: 15px;

			div {
				span {
					display: inline-block;
				}

				span:nth-child(1) {
					padding-right: 10px;
					width: 160px;

					text-align: right;

					.font-bold;
				}
				span:nth-child(2) {

				}
			}

			.show {
				background-color: @color-primary;
				border: 1px solid @color-primary;
			    display: inline-block;
			    margin: 10px 0 10px 170px;
				padding: 0 20px;

				-webkit-border-radius: 3px;
				border-radius: 3px;

				color: @white;
				cursor: pointer;
				font-size: 13px;
				line-height: 32px;
				outline: 0;
				text-align: center;
				text-decoration: none;

				.font-regular;
			}

		}
	}
}
