ul.feedbackPanel {
	background-color: @color-background;
	margin-bottom: 20px;
	padding: 10px;

	color: @color-primary;

	li.feedbackPanelERROR {
		line-height: 22px;
	}
}