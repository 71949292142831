#content-container {
	margin: auto;
	width: @containerWidth;

	#left {
		background-color: @color-background;
		float: left;
		margin-top: 44px;
		//padding: 15px 12px;
		width: 200px;
	}

	.bloc-menu {

		h2 {
		    padding: 15px 12px;

			font-size: 16px !important;
			line-height: normal;

			.font-regular;
		}

		.bloc-content {
		    padding: 15px 12px;
		}

		ul.menu-items .is-current-item span{color:@color-primary !important;}

		li.menu-item {
			display: block;
		    padding: 0 0 8px 0;

			a.menu-item-link, span.menu-item-link {
				color: @black;
				line-height: normal;
				text-decoration: none;

				&:hover{
					text-decoration: underline;
				}

				&.disable {
					cursor: not-allowed;
					pointer-events: none;
				}
			}

		}


	}

	.bloc-menu-signout {
	    margin-top: 15px;
	    padding-bottom: 15px;

			text-align: center;

			.button{

				&:hover{
					background: @color-intermediate;
					border-color: @color-intermediate;
				}

			}
	}

}
