#contract-paper-sign {

	#mainContent {

		div.panel-inner {
			 & > div {
			 	padding-bottom: 15px;
			 }

			 .buttons {
			 	margin: 10px 0 20px;
			 }

		}

	}
}