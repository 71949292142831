#contract-item-edit {
	
	h3 {
		padding: 20px 0;

		color: @color-primary;
		font-size: 18px;
		text-transform: uppercase;

		.font-bold;
	}

	label {
		vertical-align: top;
	}

	.field-locality {
		input[type="text"] {
			margin-right: 10px;
			width: 141px;
		}
	}

	.wicket-aa {
		background: @white;
    	border: 1px solid @color-background;
    	padding: 5px;

		li {
			line-height: 18px;
		}
	}

	.button2.help-link {
		background-color: @color-primary;
		border: 1px solid @color-primary;
	    display: inline-block;
	    margin: 0 0 20px 125px;
		padding: 0 20px;

		-webkit-border-radius: 3px;
		border-radius: 3px;

		color: @white;
		cursor: pointer;
		font-size: 13px;
		line-height: 32px;
		outline: 0;
		text-align: center;
		text-decoration: none;

		.font-regular;
	}

	table.buttons {
		display: block;
		margin: 20px 0 40px;
		width: 100%;

		tbody, tr {
			display: block;

			&:hover {
				background-color: transparent;
			}
		}

		td {
    		border-top: 0;
    		display: inline-block;
		}
	}

	.cnil {
		margin: 20px 0;

		color: @color-intermediate;
		font-size: 12px;
	}
}