#be-consult-page{

      #mainContent{
            margin-top: 70px;

            .button.back{
                  margin-top: -115px;
            }
      }
      #content-container #left{
            margin-top: 0;
      }
      .be-mandat-consult{
            margin-top: -10px;
      }
      .sub-bloc{
            border:1px solid white;
            margin-top: 30px;

            .sub-title{
                  padding: 10px;
                  background: white;
                  margin-bottom: 10px;
            }

            .double a{
                  color:@color-primary;
                  text-decoration: none;
            }
            form{
                  float: left;
                  margin-top: 10px;
                  margin-right: 20px;
            }
      }
}
