#bd-view-mandate-page {

	#tbl-contract-holders {
		margin: 20px 0;
	}

	.buttons {
		margin: 20px 0;

		.button, form {
			display: inline-block;
		}

		.button.back {
			margin-right: 120px;
		}


	}

	select {
		-webkit-appearance:none;
		background: @white;
		border: 0;
		border-radius: 4px;
		outline: 0;
		padding: 0 3px;

		position: absolute;
		top: 0;
		bottom: 0;

		width: 100%;

		//display: block;
		height: 26px;
		padding: 0 0 0 8px;
		overflow: hidden;
		position: relative;
		border: 1px solid @color-input;
		white-space: nowrap;
		line-height: 26px;
		color: #444;
	}
	.bloc_content {
		border: 1px solid @color-background;
		margin-bottom: 20px;
		padding: 15px;

		div {
			line-height: 26px;
		}

		label {
			display: inline-block;
			min-width: 180px;
			padding-right: 20px;

			text-align: right;

			.font-bold;
		}

		span {
			display: inline-block;
		}
		.subTitle{
			background-color: lightgray;
		}
	}
	.button.validate{
		margin-top: 20px;
	}
}
