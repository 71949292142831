.AlertsSelectedMob {
	padding:15px;
	background:#ededed;
	margin: 40px 0 40px;
	background-color: @color-background;


	&-iconWrapper {
		display: inline-block;
    	width: 10%;
    
    	text-align: center;
	    vertical-align: middle;
	}

	&-icon {
		border-radius: 50%;
	    border: 2px solid @color-primary;
	    width: 20px;
	    height: 20px;
	    display: inline-block;
	    line-height: 21px;
	    color: @color-primary;
	    font-weight: bold;

	    &:before {
	    	content: "i";
	    	font-family: "Georgia";
	    }
	}

	&-content {
		display: inline-block;
	    width: 85%;

	    vertical-align: middle;
	}

}