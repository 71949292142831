#contract-item-collect #mainContent {

	h1 {
		display: block;
	}

	.contract-item-collect-search-text {
		padding-bottom: 30px;

		font-size: 16px;
	}

	.double.field-search-text {

		label {
			display: inline-block;
			width: 15%;
		}

		input {
			border: 1px solid @color-background;
			display: inline-block;
			padding: 0 10px;
			width: 75%;

			line-height: 28px;
		}
	}

	.search-results {
		margin: 30px 0;
		padding-left: 15%;

		.search-results-inner {

			.search-result-opc-found-label {
				padding: 0 0 10px 3px;

				color: @color-intermediate;
				font-size: 16px;
			}

			.search-result-opc-found ul {
				padding-left: 15px;

				li {
					margin-bottom: 2px;

					line-height: 30px;

					a {
						color: @black;
						text-decoration: none;
					}

					.iconed.imgbutton.save {
						float: right;
						position: relative;

						text-indent: -99999px;

						&:after {
							background-color: @color-primary;
						    content: "+";
							display: block;
						    height: 20px;
						    position: absolute;
						    
						    right: 0;
						    top: 0;
						    width: 20px;
						    
						    
						    color: @white;
						    font-size: 18px;
						    line-height: 22px;
						    text-align: center;
						    text-indent: 0;

							&.disable {
								display: none;
							}

						}
					}

				}
			}

			.search-result-separator-label {
				padding: 15px 0;
			}

			.search-result-opc-new {
				.special {
					padding: 20px 0;

					a, select {
						margin: 0 10px 0 0;
					}
				}
			}

			.special .imgbutton, .special .opcrole-link {
				background-color: @color-primary;
				border: 1px solid @color-primary;
			    display: inline-block;
				padding: 0 20px;

				-webkit-border-radius: 3px;
				border-radius: 3px;

				color: @white;
				cursor: pointer;
				font-size: 13px;
				line-height: 32px;
				outline: 0;
				text-align: center;
				text-decoration: none;

				.font-regular;
			}

			.special select {
				background: @white;
				border: 1px solid @color-background;
				border-radius: 0;
				-webkit-border-radius: 0;
				box-shadow: none;
				display: inline-block;			
				margin: 0;
				outline: none;
				padding: 3px 4px 3px 10px;
				position: relative;
				
				-webkit-box-shadow: none;

				color: @color-primary;
				cursor:pointer;
				font-size: 12px;
				-webkit-appearance: none;
				appearance: none;

				&:after {
					background-color: blue;
					content: ">";
					display: block;
					height: 15px;
					position: absolute;
					width: 15px;
				}
			}

			

		}

	}


	.bloc-opcRole-tree {
		margin-top: 20px;

		white-space: nowrap;
	}

	#categories, #opcroles {
		display: inline-block;
		width: 50%;

		vertical-align: top;
	    white-space: normal;

		.info {
			display: none;
		}

		h2 {
			background-color: @color-background;
			padding: 0 15px;

			color: @color-intermediate;
			font-size: 14px;
			line-height: 30px;
		}

		#categories-list, #opcrole-list {
			margin-top: 10px;

			li, div.row, div.row-selected {
				border-bottom: 1px solid @color-background;
				padding: 0 15px;

				line-height: 30px;
			}

			a {
				color: @color-intermediate;
				font-size: 14px;
				text-decoration: none;
			}
		}

		#categories-list {
			.row .a_ {
				max-width: 100%;
				overflow: hidden;

			    text-overflow: ellipsis;
			    white-space: nowrap;

			    a {
			    	display: block;
			    }

			    a:hover {
			    	color: @color-primary;
			    }
			}

			.row-selected a {
				color: @color-primary;
			}
		}

	}



	#opcroles {
	    margin-left: -2px;

	    #opcroles-list {

	    	a.iconed.imgbutton.save {
	    		float: right;
	    		position: relative;

	    		text-indent: -99999px;

	    		&:before {
	    			background-color: @color-primary;
	    			content: "+";
	    			display: block;
	    			margin: 3px 0;
	    			padding: 2px 5px 0px 5px;
	    			position: absolute;
	    			right: 0;

	    			color: @white;
	    			font-size: 22px;
	    			line-height: 22px;
	    			text-indent: 0;
	    		}

	    	}
	    }
	}

	#pager {
	    height: 31px;

		text-align: right;

		a, .goto, .first, .prev, .next, .last {
			background-color: @color-primary;
			display: inline-block;
		    height: 24px;
	        margin-right: 4px;
			width: 24px;
    
    		color: @white !important;
    		line-height: 24px;
    		text-align: center;

			&.disable {
				display: none;
			}

		}

		a {
		    margin-left: -4px;
		}

		.goto {
		    margin-right: 8px;

		    a {
		    	margin-right: 0;
		    }

		    &:last-child {
		    	margin-right: 8px;
		    }
		}

		a.next {
		    margin-left: -8px;
		}


	}

	.buttons {
		margin: 25px 0;
	}


}
