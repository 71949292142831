.wicket-mask {
	background: fade(@black,70);
	bottom: 0 !important;
	left: 0 !important;
	position: fixed !important;
	right: 0 !important;
	top: 0 !important;
	width: 100% !important;
}

.wicket-modal {
	background-color: @white;
	padding: 40px 20px 20px;
	z-index: 25000;

	text-align: center;

	iframe {
		display: none;
		margin: 20px 0;
	}

	h3 {
		padding: 0 0 20px;

		font-size: 18px;
		font-weight: bold;
	}

	p {
		margin: 0 auto;
		max-width: 85%;
		padding: 0 0 10px;
	}

	.buttons {
		padding: 20px 0;

		.button {
			margin: 0 10px !important;
		}
	}
}