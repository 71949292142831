#header {
	background-color: @white;
	display: block;
	height: 70px;
	margin-bottom: 40px;
	padding-top: 10px;

	// Box Shadow
	-webkit-box-shadow: 0px 2px 20px 0px #cfcfcf;
	box-shadow: 0px 2px 20px 0px #cfcfcf;
	filter:progid:DXImageTransform.Microsoft.Shadow(color=#cfcfcf, Direction=180, Strength=20);

	.inner-banner {
		height: 80px;
		margin: auto;
		width: @containerWidth;
	}

	#logo {
		background-image: url(@logoUrl);
		background-position:0px ;
		background-repeat: no-repeat;
		background-size: contain;
		display: block;
		height: @logoHeight;
		width: @logoWidth;
	}

	#title {
		position: relative;
		left: 240px;
		margin-top: -33px;

		&:before {
			content: @serviceName;

			color: @color-primary;
			font-size: 16px;

			.font-bold;
		}
	}

}
