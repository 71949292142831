#regulated-choice {

	.bloc-content > p {
		padding-bottom: 10px;

		&:nth-child(2) {
			padding-bottom: 20px;
		}
	}

	.buttons {
		margin: 0 0 20px 10px;

		.help {
			padding: 5px 0 10px;

			color: @black;
			font-size: 14px;
		}
	}

	.help {
		color: @color-intermediate;
		font-size: 12px;
	}

}