// VARS

// COLORS
@black: #373737;
@white: #fff;

// SPECIFIC
@color-primary: #4e1b69;
// @color-secondary: #0098ec;
@color-intermediate: #666;

@color-background: #EFEFEF;
@color-input: #999;

@color-background-title-bloc-content: #DDD;
@color-title-bloc-content: #666;

@color-background-bloc-content: #f4f4f4;

// SIZES
@containerWidth: 1024px;



// URLS or STRINGs in VARS
@logoUrl: "../img/logo-nps_agency.jpg";
@logoHeight: 62px; //(Add + 15px for background position)
@logoWidth: 214px;

@serviceName: "Bienvenue";
