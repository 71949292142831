#contract-sign {
	
	#mainContent {

		.panel-inner {
			border: 1px solid @color-background;
			padding: 15px;

			p {
				padding-bottom: 15px;
			}
		}

		h2 {
		    margin: -30px -15px 15px -15px;

			div {
				display: inline;

				&:after {
					content: ":";
					display: inline-block;
					padding-left: 5px;
				}
			}
		}

	}

}