h1 {
	padding-bottom: 20px;

	color: @color-primary;
	font-size: 24px;
	text-transform: uppercase;

	.font-bold;
}

h2 {
	background: @color-background-title-bloc-content;
	padding: 0 20px;

	color: @color-title-bloc-content;
	font-size: 16px;
	line-height: 40px;

	.font-bold;
}

h3 {}

h4 {}