#mreg-permanent-detail {

	#mainContent {

		.bloc.detail-file {
		    border: 1px solid @color-background;
			margin-bottom: 15px;
			padding: 15px;

			div {
				line-height: 22px;

				span {
					display: inline-block;
				}

				span:nth-child(1) {
					padding-right: 10px;
					width: 160px;

					text-align: right;

					.font-bold;
				}
				span:nth-child(2) {

				}
			}
		}

		.buttons {
			margin: 15px 0;
		}

	}

}