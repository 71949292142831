#contract-edit {

	.comment {
		margin-bottom: 20px;

		color: @color-intermediate;
	}

	.with-tooltip-marker::before {
	    position: absolute;
	}

	.table-header {
		margin: 0 0 20px 0;
	}

	.double label.required{
		position: relative;
	}

	.field-owner-birthCity, .field-representative-birthCity {

		.owner-birthCity, .representative-birthCity {
			width: 146px;
		}

		.select2-container {
			min-width: 150px;
		}

	}

	.field-owner-zipCodeForeign {
		input:nth-child(2) { width: 70px; }
		input:nth-child(3) { width: 218px; }
	}

	.multitiers {

		& > div {
			border-bottom: 1px solid @color-background;
			margin-bottom: 15px;
		}

		.bloc-multiowner {
			padding: 20px 0;

			h3 {
				background-color: @color-background;
				display: block;
				margin: 0 0 10px 213px;
				padding: 10px;
				width: 287px;

				color: @color-intermediate;

				.font-bold;
			}
		}
	}

	.buttons {
		margin-bottom: 20px;
		text-align: right;
	}
	label.required:after{
		content:"*";
		color:#dc1220;
	}
	label.oneRequired:after {
		content:' **';
		margin-right: -9px;
		color: #dc1220;
	}

}
