// FONTS
.font-regular {
	font-family: "Arial";
	font-weight: normal;
}

.font-bold {
	font-family: "Arial";
	font-weight: bold;
}
