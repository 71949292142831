// BUTTONS
.buttons{
	margin: 10px;
}
.button, .button2 {
	background-color: @color-primary;
	border: 1px solid @color-primary;
	display: inline-block;
	padding: 0 20px;

	-webkit-border-radius: 3px;
	border-radius: 20px;

	color: @white;
	cursor: pointer;
	font-size: 13px;
	line-height: 32px;
	outline: 0;
	text-align: center;
	text-decoration: none;

	.font-regular;

	&:hover{
		background: @color-intermediate;
		border-color: @color-intermediate;

		color: @white;
	}

	&.back, &.annuler {
		background-color: @white;
		border-color: @color-intermediate;

		color: @color-intermediate;
	}

	&.disable {
		background-color: rgba(0,0,0,.3);
		border: 1px solid transparent;
		opacity: 0.5;

		cursor: not-allowed;
	}
}

#contract-paper-sign a.button.back {float: left;}
