#vm-page {

	.question {
		background: @color-background;
		padding: 0 20px;

		color: @color-intermediate;
		font-size: 16px;
		line-height: 40px;

		.font-bold;
	}

	.answer {
		border: 1px solid @color-background;
		padding: 15px;
	}

}