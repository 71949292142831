#report {
	#content {
		.statForm {
                  background: white;
			border: 1px solid gray;
			margin: 10px;
			padding: 20px;

			.button2 {
				margin: 0 10px;
			}
		}
		.button2 {
			&:hover {
				span {
					color: white;
				}
			}
		}
		.bloc-report-query {
			input {
				width:100px;
			}
		}
		.buttons {
			text-align:center;
			width: auto;
    			margin-top: 30px;

			&:not(first-child){
					height:auto;
				}
			}

		.bloc-content>.buttons {
			display: block;
			margin-top: 10px;
			width: 100%;
			.button2 {
				display: inline-block;
				margin-bottom: 10px;
			}
		}
		.field-from-date {
			display: block;
			height: 30px;
			margin-bottom: 10px;
			label {
				display: inline-block;
				width: 100px;
			}
		}
		.field-to-date {
			display: block;
			height: 30px;
			margin-bottom: 10px;
			label {
				display: inline-block;
				width: 100px;
			}
		}
		.mobilityType {
			display: block;
			margin-top: 25px;
			width: 200px;
			.field-MBR {
				margin-right: 20px;
				input {
					width:20px;
				}
			}
			.field-MBNR {
				margin-right: 20px;
				input {
					width:20px;
				}
			}
		}
		.statDisp {
			display: inline-block;
			margin-left: 30px;
			padding-top: 10px;
			text-decoration: underline;
			width:100%;
		}
	}
}
