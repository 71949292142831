#be-contract-list-page{
      .contractTable{
             thead th:nth-child(1){
                   width:31%;
             }
             thead th:nth-child(2){
                   width:15%;
             }
             thead th:nth-child(3){
                   width:28%;
             }
      }
      .yui-skin-sam .yui-calcontainer.withtitle{
            width: 300px;
      }
}

.field-contract-search-contractType select {
	width: 308px;
	padding: 5px 3px;
	outline: none;
	border: 1px solid #999;
}
