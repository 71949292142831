body {
	font-size: 14px;
	padding-bottom: 40px;
	.font-regular;
}

.welcome, .welcome2 {
	display: block;
	padding-bottom: 10px;
}

.hidden-submit {
	display: none;
}


// Revoke link
.revok-link {
	a {
		color: @color-primary;
		text-decoration: none;
	}
}
