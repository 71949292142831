.bloc-inner {
	margin-bottom: 30px;

	& > .bloc-content {
		background-color: @color-background-bloc-content;
		border: 1px solid @color-background-bloc-content;
		padding: 15px;

		.bloc-content {
			border: 0;
			padding: 0;
		}
	}

}
