#bd-consult-page{

      .sub-title{
            background-color: @color-background-title-bloc-content;
            line-height: 25px;
            width: 100%;
            display: inline-block;
            padding: 10px 0px 10px 10px;
            box-sizing: border-box;
      }
      .double label{
            font-size: 15px;
      }
}
