#contact #mainContent {

	h2 {
		background-color: transparent;
		padding: 0;

		color: @black;

		.font-regular;
	}

	.bloc-content {
		border: 0;
		padding: 0;
	}

	.bloc-inner {
		background-color: @color-background;
		padding: 10px;
	}

	.bloc-mail {
		background-color: @color-background;

		.bloc-inner {
			background-color: transparent;
			padding: 0;
		}

		h2 {
			background: @color-background-title-bloc-content;
			padding: 5px 20px;

			color: @color-title-bloc-content;
			font-size: 16px;
			line-height: 40px;

			.font-bold;
		}

		.bloc-content {
			border: 1px solid @color-background;
			padding: 15px;
		}

		.field-mail-account {
			.info {
				display: none;
			}
		}
	}
}