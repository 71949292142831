.iban-field-container,
.field-contract-search-iban {

	& > div {
		display: inline-block;
		//width: 72%;
	}

	label {
		padding-top: 3px;
		position: relative;

	    vertical-align: text-bottom;
	}

	input {
		width: 32.6px;
	}

}

.iban-field-container {
	display: inline;
}

.field-new-account-iban	label {
	margin-left: -15px;
  padding-right: 25px;
	position: relative;
	width: 200px;

	& > div {
		display: block;
		position: absolute;
		right: 10px;
		top: 0;

		& > div.withtooltip {

		}
	}
}

.with-tooltip-content {
	display: none;
}
