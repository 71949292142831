.horizontal-navigation {
	margin: 20px 0;
	position: relative;

	button.button {
		background-color: @color-background;
		border: 0;
		border-left: 8px solid @white;
		border-radius: 0;
		display: inline-block;
		margin-left: -4px;
		padding: 8px;
		width: 24%;

		.font-bold;

		&:first-child {
			border-left: 0;
			margin-left: 0;
		}

		.button-label {
			display: block;
		    padding: 0 20px;

			color: @color-intermediate;
			font-size: 14px;
			line-height: 18px;
		}

		&:hover {
			background-color: @color-primary;

			.button-label {
				color: @white;
			}
		}

		&.active {
			background-color: @color-primary;

			.button-label {
				color: @white;
			}

			&:after {
				background-color: @color-primary;
				content: "";
				display: block;
				height: 1px;
				left: 0;
				position: absolute;
				top: 51px;
				width: 100%;
			}
		}

	}

}
