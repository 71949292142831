#content-container {
	width: 100%;

	// .column {
	// 	display: block;
	// 	width: @containerWidth;
	// }

	#content {
	    margin-left: 240px;

			.center{
				margin: 10px 0;
				 div {
			    display: inline-block;
			    top: 10px;
			    position: relative;
				}
			}
	}
}

// .bloc-content {
// 	border: 1px solid @color-background;
// 	padding: 25px;
// }
