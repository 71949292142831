//
// TOOLTIP TRASH
.qtip.qtip-default.qtip-pos-tc.qtip-fixed {
    margin: 5px 0 0 3px;
    position: absolute;

    max-width: 260px;

    text-align: center;
}
.with-tooltip {
    display: inline-block;
    width: auto;
}
.with-tooltip-marker, .with-tooltip-marker:hover {
    color: transparent;
    cursor: pointer;
}

form{
  .double.field-new-account-iban{

    .with-tooltip-marker {
        margin: 0;
    }
  }
}

.with-tooltip-marker{

    &:before {
        background-color: @color-primary;
        border-radius: 50%;
        content: "?";
        display: inline-block;
        height: 21px;
        right: -10px;
        top: 7px;
        width: 21px;

        color: @white;
        font-size: 12px;
        line-height: 23px;
        text-align: center;
    }

    &:hover:before {
        background-color: fade(@color-primary, 70);
    }
}

.qtip {
	position: absolute;
	left: -28000px;
	top: -28000px;
	display: none;
	max-width: 200px;
	min-width: 50px;
	font-size: 10.5px;
	line-height: 12px;
	direction: ltr;
	box-shadow: none;
	padding: 0;
	margin-top: 20px;
	line-height: 20px;
}
.qtip-default {
    border: 1px solid #ccc;
    background-color: #FFF;
    color: #555;
    border-radius:5px;
    box-shadow: 0 3px 7px rgba(0,0,0,.2);
}
.qtip-content {
    padding: 15px 15px;
    text-align: left;
    word-wrap: break-word;
}
.qtip-content, .qtip-titlebar {
    position: relative;
    overflow: hidden;
}



#mreg-follow-up  .with-tooltip,
#mreg-follow-up-recurrent-transfer  .with-tooltip,
#mreg-follow-up-recurrent-levy  .with-tooltip{
    cursor: pointer;
    display: block;
    float: left;
    margin: -20px 0 0;
    width: 20px;
}
#mreg-follow-up table .with-tooltip {
    display: inline;
    float: none ;
}
#mreg-follow-up #mainContent .bloc_content .with-tooltip-content{display: none;}
#mreg-follow-up #mainContent .bloc_content .with-tooltip{display: inline;position: absolute;}
