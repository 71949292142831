#contract-search {

	#form-contract-search {

		.bloc-form {
			line-height: 32px;
		}

		label {
			display: inline-block;
			padding-right: 10px;
			width: 180px;

			line-height: normal;
			text-align: right;

			.font-bold;
		}

		input {
			display: inline-block;
		}

		.buttons {
			margin: 10px 0 20px 215px;
		}

	}

	.results {
		table tbody td {
			.button{
				display: block;
			}
			.delLink{
				line-height: 20px;
				display: block;
				text-align: center;
				color: black;
			}
		}
	}
	.delLink{
		color: @color-primary;
	}
}
#bd-contract-list-page {

	#form-bd-contract-search{

		input[type=checkbox]{
			margin-left: 214px;

			&:first-child{
				margin-top: 20px;
			}
		}
	}
	.results{
		th{
			line-height: 15px;
			display: table-cell;
			vertical-align: middle;
			padding-left: 10px;
			padding-top:20px;
			padding-bottom: 20px;
			a{
				text-decoration: none;
				color: @color-title-bloc-content;
			}
			&:nth-child(1){
				width: 97px;
			}
			&:nth-child(2){
				width: 97px;
			}
			&:nth-child(3){
				width: 100px;
			}
			&:nth-child(4){
				width: 100px;
			}
			&:nth-child(5){
				width: 135px;
			}
			&:nth-child(6){
				width: 100px;
			}
		}
		td{
			display: table-cell;
			vertical-align: top;
			word-wrap: break-word;

			&:last-child{
				word-wrap: normal;
				vertical-align: middle;
			}
		}
	}
}
