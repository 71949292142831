#ui-datepicker-div {
	background: @white;
	border: 1px solid @color-background;
	display: block;
	padding: 10px;
	width: 220px;

	table {
		table-layout: auto;

		th, td {
			width: auto !important;
		}
	}

	.ui-datepicker-header {
		background-color: @color-background;
		padding: 10px;

		color: @color-intermediate;

		.ui-datepicker-prev {
			float: left;
		}

		.ui-datepicker-next {
			float: right;
		}

		.ui-datepicker-title {
			padding: 18px 0 0;
			color: @color-primary;
			text-align: center;
			text-transform: uppercase;

			.font-bold;
		}

	}

	.ui-datepicker-calendar {
		//padding: 10px;
	}

	table {

		tr {

			&:hover {
				background-color: transparent;
			}
		}

	}
}
