// FORMS

.double {
	line-height: 36px;

	label, input, .selectWrap, textarea {
		display: inline-block;
		font-size: 11px;
	}

	label {
		min-width: 200px;
		padding-right: 10px;

		text-align: right;

		.font-bold;
	}

	input, textarea {
		border: 1px solid @color-input;
		outline: none;
		padding: 5px 3px;
		width: 300px;

		&[type="checkbox"], &[type="radio"] {
			width: auto !important;
		}
		&.new-account-zipCode{
			width:50px;
		}
		&.new-account-city{
			width:238px;
		}
	}
	input, textarea ,select{
		font-size: 13px;
		color: @black;
	}
	.selectWrap {
		display: inline-block;
		height: 25px;
		position: relative;
		width: 308px;

		vertical-align: middle;

		select {
			-webkit-appearance:none;
			background: @white;
			border: 0;
			border-radius: 4px;
			outline: 0;
			padding: 0 3px;

			position: absolute;
			top: 0;
			bottom: 0;

			width: 100%;

			//display: block;
			height: 26px;
			padding: 0 0 0 8px;
			overflow: hidden;
			position: relative;
			border: 1px solid @color-input;
			white-space: nowrap;
			line-height: 26px;
			color: #444;
		}
	}

	// Specific JS SELECT
	.select2-container {
		min-width: 308px;
	}

	// Specific for Checkbox
	.agent-checkbox-help {
	    display: inline-block;
    	padding-top: 11px;
    	width: 390px;

    	line-height: 16px;
    	vertical-align: top;

	}
}
